import React, { useEffect } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box, CssBaseline } from "@material-ui/core";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header";
import Body from "./components/body/body";
import Footer from "./components/footer/footer";
import Profile from "./components/Profile/Profile";
import PND from "./components/PND/PND";
import { addAboutMe, addRepo } from "./reducers/actions";
import { connect } from "react-redux";

function App(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#FFAF29",
          },
          secondary: {
            main: "#8539BE",
          },
          background: {
            paper: prefersDarkMode ? "#424657" : "#fff",
            default: prefersDarkMode ? "#333646" : "#fafafa",
          },
        },
      }),
    [prefersDarkMode]
  );

  useEffect(() => {
    props.aboutMe();
    props.repo();
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box>
          <Header />
          <Switch>
            <Route path="/profile" component={Profile} />
            <Route path="/" component={Body} exact={true} />
            <Route path="*" component={PND} />
          </Switch>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  aboutMe: () => dispatch(addAboutMe()),
  repo: () => dispatch(addRepo()),
});

export default connect(null, mapDispatchToProps)(App);
