// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAEqLFIPXryobCB--EZ6w9UGGOrjyl9ww",
  authDomain: "myself-fee00.firebaseapp.com",
  databaseURL: "https://myself-fee00.firebaseio.com",
  projectId: "myself-fee00",
  storageBucket: "myself-fee00.appspot.com",
  messagingSenderId: "1065760951483",
  appId: "1:1065760951483:web:eaada94e5d0664a0d32d9e",
  measurementId: "G-WF0XZ4Z7W0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);