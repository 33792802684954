import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import aboutme from "../../image/aboutme.png";
import Title from "../Title/Title";
import CustomGrid from "../CustomGrid/CustomGrid";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    background:
      theme.palette.type === "dark"
        ? theme.palette.primary.dark
        : theme.palette.grey["200"],
    color: theme.palette.common.white,
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  bioCenterBox: {
    margin: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
  bioContainer: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  bio: {
    fontWeight: 300,
    lineHeight: 1.8,
    color: theme.palette.text.secondary,
    fontSize: "calc(20rem / 16)",
  },
  skillsContainer: {
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  listSkills: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  listSkill: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  subHeader: {
    textTransform: "capitalize",
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.light
        : theme.palette.secondary.dark,
    background: theme.palette.type === "dark" ? "#2e313d" : "#f4f4f4",
    letterSpacing: "calc(2rem / 16)",
  },
  skillItemText: {
    textTransform: "capitalize",
    fontSize: "1rem",
  },
  workExperienceBox: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(6),
      marginBottom: theme.spacing(8),
    },
  },
}));

const Profile = ({ detailBio, skills, workExperience }) => {
  const classes = useStyles();
  const skillTabKeys = Object.keys(skills);
  return (
    <Box>
      <Box className={classes.title} p={4}>
        <Typography component="div">
          <Box
            textAlign="center"
            fontWeight="fontWeightLight"
            fontSize="h2.fontSize"
          >
            ABOUT
          </Box>
        </Typography>
      </Box>
      <Box>
        <Box display="flex" justifyContent="center" p={4} m={2}>
          <Avatar alt="Dhruv Mehta" src={aboutme} className={classes.large} />
        </Box>
        <Box className={classes.bioCenterBox} m={2}>
          <Title title="Just Some Bio" />
          <Typography component="div">
            <Box className={classes.bioContainer}>
              {detailBio.split("\n").map((detailPara, index) => {
                return (
                  <Typography
                    key={index}
                    paragraph={true}
                    className={classes.bio}
                  >
                    {detailPara}
                  </Typography>
                );
              })}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.skillsContainer} p={2}>
        <Title title="My skills" />
        <Paper className={[classes.paper, classes.listSkills].join(" ")} p={0}>
          {skillTabKeys.map((skillKey) => {
            const skillArray = skills[skillKey] && skills[skillKey].split(",");
            return (
              <List
                key={skillKey}
                dense={true}
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" className={classes.subHeader}>
                    {skillKey}
                  </ListSubheader>
                }
                className={classes.listSkill}
              >
                {skillArray &&
                  skillArray.map((skill) => {
                    return (
                      <ListItem key={skill}>
                        <ListItemText
                          primary={skill}
                          className={classes.skillItemText}
                        />
                      </ListItem>
                    );
                  })}
              </List>
            );
          })}
        </Paper>
      </Box>
      <Box className={classes.workExperienceBox}>
        <Title title="Work Experience" />
        <Box >
          <CustomGrid spacing={4} justify="center">
            {workExperience &&
              workExperience.map((workItem, index) => {
                return (
                  <CustomCard
                    key={index}
                    item={{
                      title: `${workItem.title}`,
                      description: `${workItem.description}`,
                      date: `${workItem.date}`,
                      companyName: `${workItem.companyName}`,
                    }}
                  />
                );
              })}
          </CustomGrid>
        </Box>
      </Box>
    </Box>
  );
};

const CustomCard = ({ item }) => {
  const useStyles = makeStyles(() =>
    createStyles({
      card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      },
      cardTitle: {
        textTransform: "capitalize",
      },
    })
  );
  const classes = useStyles();
  return (
    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
      <Card className={classes.card}>
        <CardContent>
          <Box>
            {item.date ? (
              <Typography
                variant="body1"
                component="h2"
                color="primary"
                gutterBottom
              >
                {item.date.trim()}
              </Typography>
            ) : null}
          </Box>
          <Box display="flex" flexDirection="row" mt={1}>
            <Box>
              {item.companyName ? (
                <Avatar
                  alt={item.companyName}
                  src={`${item.companyName}.jpg`}
                  sx={{ width: 48, height: 48 }}
                />
              ) : null}
            </Box>
            <Box display="flex" flexDirection="column" ml={1}>
              <Box>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.cardTitle}
                >
                  {item.title}
                </Typography>
                {item.description ? (
                  <Typography
                    variant="body1"
                    component="h2"
                    color="textSecondary"
                    gutterBottom
                  >
                    {item.description.trim()}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { about } = state.aboutMe;
  return {
    detailBio: about.bioMe,
    skills: { ...about.skills },
    workExperience: [...about.workExperience],
  };
};

export default connect(mapStateToProps, null)(Profile);
