import { onValue, ref } from "firebase/database";
import { database } from "../FirebaseConfig";
import * as actionTypes from "./actionTypes";

function setAboutMe(about) {
  return { type: actionTypes.ADD_ABOUT, about };
}

function setRepo(repo) {
  return { type: actionTypes.ADD_REPO, repo };
}

export function addAboutMe() {
  return (dispatch) => {
    return onValue(
      ref(database, "/about"),
      (snapshot) => {
        dispatch(setAboutMe(snapshot.val()));
      },
      {
        onlyOnce: true,
      }
    );
  };
}

export function addRepo() {
  return (dispatch) => {
    return onValue(
      ref(database, "/repo"),
      (snapshot) => {
        dispatch(setRepo(snapshot.val().list));
      },
      {
        onlyOnce: true,
      }
    );
  };
}
