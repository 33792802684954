import React from "react";
import {Typography} from "@material-ui/core";

const PND = () => {
    return (
        <Typography variant="h1" component="h1">
            Page Not Found
        </Typography>
    );
};

export default PND;
