import React from "react";
import Button from "@material-ui/core/Button";
import BoxContainer from "../../hoc/BoxContainer/BoxContainer";
import "./hero.css";
import aboutme from "../../image/aboutme.png";
import {makeStyles, Typography, Box, Grid, Link} from "@material-ui/core";
import SocialContainer from "../SocialContainer/SocialContainer";

const Hero = (props) => {
  const useStyles = makeStyles((theme) => ({
    hero: {
      textAlign: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        textAlign: "left",
      },
      [theme.breakpoints.up("md")]: {
        height: "95vh",
      },
    },
    left: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center"
      },
    },
    contactMeButton: {
      padding: "16px 36px",
      margin:"8px",
      borderRadius: "24px"
    },
    heroResumeButton: {
      color: "white",
      padding: "16px 36px",
      margin:"8px",
      fontSize: "0.8rem",
      borderRadius: "24px",
    },
    heroAboutText: {
      letterSpacing: 4,
      fontWeight: 400,
    },
    heroAbout: {
      margin: "32px 16px",
      display: "inline-flex",
      letterSpacing: 2,
      alignItems: "center",
    },
    heroImageContainer: {
      display: "inline-flex",
      justifyContent: "center",
      overflow: "hidden",
      borderRadius: theme.shape.borderRadius,
      // [theme.breakpoints.up("sm")]: {
      //   display: "none",
      // },
    },
    heroImage: {
      width: 200,
      [theme.breakpoints.only("xs")]: {
        width: "80%",
      },
    },
    heroIntroTitle: {
      margin: "16px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center"
      },
    },
    heroAboutMeText: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center"
      },
    }
  }));
  const classes = useStyles();
  return (
    <BoxContainer m={8}>
      <Grid container className={classes.hero} spacing={2}>
        <Grid item className={classes.left} lg={6} md={6} xs={12} sm={12}>
          <div className="hero-intro">
            <div className={classes.heroIntroTitle}>
              <div className="title-prefix">
                <div className="title-prefix-1">
                  <Typography variant="h1" component="h1" color="primary">
                    Hello,
                  </Typography>
                </div>
                <div className="title-prefix-2">
                  <Typography variant="h1" component="h1">
                  {'\u00a0'}Im
                  </Typography>
                </div>
              </div>
              <div className="title-main">
                <Typography variant="h3" component="h3">
                  Dhruv Mehta
                </Typography>
              </div>
              <Typography
                variant="h4"
                component="h1"
                color="primary"
              >
                -{" "}
              </Typography>
            </div>
          </div>
          <SocialContainer/>
        </Grid>
        <Grid item className="right" lg={6} md={6} xs={12} sm={12}>
          <Box className={classes.heroImageContainer}>
            <img src={aboutme} alt="Dhruv" className={classes.heroImage} />
          </Box>
          <Box className={classes.heroAbout}>
            <Typography
              variant="body2"
              component="h5"
              className={classes.heroAboutText}
              gutterBottom
            >
              {props.about.intro}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.heroAboutMeText}>{props.about.aboutMeText}</Typography>
          </Box>
          <Box marginX={2} marginY={3}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.contactMeButton}
              component={Link}
              href="/#contact"
            >
              Contact Me
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.heroResumeButton}
              component={Link}
              href={props.about.cv_url}
              rel="noopener"
              target="_blank"
            >
              Get CV
            </Button>
          </Box>
        </Grid>
      </Grid>
    </BoxContainer>
  );
};

export default Hero;
