import {Grid} from "@material-ui/core";
import React from "react";

const CustomGrid = (props) => {
    return (
        <Grid
            container
            alignContent="stretch"
            justify={props.justify}
            spacing={props.spacing? props.spacing: 0}
        >
            {props.children}
        </Grid>
    );
};

export default CustomGrid;
