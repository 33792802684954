import React from 'react';
import {Box, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    Title: props => ({
        fontWeight: 100,
        letterSpacing: 10,
        display: "inline-flex",
        justifyContent: "center",
        fontSize: "2.5rem",
        position: "relative",
        color: props.textColor? props.textColor: null,
        "&:after": {
            content: "''",
            width: "60%",
            height: "2px",
            borderRadius: "20px",
            backgroundColor: props.underlineColor? props.underlineColor: theme.palette.secondary.main,
            position: "absolute",
            left: "20%",
            bottom: 0,
        },
    })
}))
const Title = (props) => {
    const classes = useStyles({underlineColor: props.underlineColor, textColor: props.textColor});
    return (
        <Typography component="div" align="center" gutterBottom={true}>
            <Box className={classes.Title}>{props.title}</Box>
        </Typography>
    );
}

export default Title;
