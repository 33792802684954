import React from "react";
import "./Projects.css";
import BoxContainer from "../../hoc/BoxContainer/BoxContainer";
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  createStyles,
  makeStyles,
  Link,
} from "@material-ui/core";
import Launch from "@material-ui/icons/LaunchRounded";
import CustomGrid from "../CustomGrid/CustomGrid";
import { connect } from "react-redux";

const Projects = ({ projects }) => {
  return (
    <BoxContainer>
      <Box className="projects-container" m={2} mb={8}>
        <Box className="project-title-container">
          <Typography
            variant="h3"
            className="project-title"
            gutterBottom
            align="center"
          >
            My Projects
          </Typography>
        </Box>
        <Box>
          <Box>
            <CustomGrid spacing={3} justify="center">
              {projects.map((project, index) => {
                return <CustomCard key={index} project={project} />;
              })}
            </CustomGrid>
          </Box>
        </Box>
      </Box>
    </BoxContainer>
  );
};

const CustomCard = ({ project }) => {
  const useStyles = makeStyles(() =>
    createStyles({
      card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      },
      cardTitle: {
        textTransform: "capitalize",
      },
    })
  );
  const trimEllip = (str) => {
    return str.length > 100 ? str.substring(0, 100) + "..." : str;
  };
  const classes = useStyles();
  return (
    <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
      <Card className={classes.card}>
        <CardContent>
          <Box>
            <Typography
              variant="h5"
              component="h1"
              className={classes.cardTitle.trim()}
              gutterBottom
            >
              {project.name}
            </Typography>
          </Box>
          <Box>
            {project.description ? (
              <Typography
                variant="body1"
                component="h2"
                color="textSecondary"
                gutterBottom
              >
                {trimEllip(project.description.trim())}
              </Typography>
            ) : null}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Launch />}
            component={Link}
            href={project.projectsUrl}
            target="_blank"
            rel="noopener"
          >
            View Project
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const { repo } = state.aboutMe;
  return { projects: [...repo] };
};

export default connect(mapStateToProps, null)(Projects);
