import React from "react";

import Navbar from "../Navbar/Navbar";

const Header = () => (
    <React.Fragment>
        <Navbar />
    </React.Fragment>
);

export default Header;
