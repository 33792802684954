import * as actionTypes from "./actionTypes";
import { combineReducers } from "redux";

const initialState = {
  about: {
    aboutMeText: "",
    intro: "",
    cv_url: "#",
    email: "mailto:",
    social_urls: {},
    bioMe: "",
    workExperience: [],
    skills: {},
  },
  repo: [],
};

function aboutMeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_ABOUT:
      return {
        ...state,
        about: {
          ...action.about,
        },
      };
    case actionTypes.ADD_REPO:
      return {
        ...state,
        repo: [...action.repo],
      };
    default:
      return state;
  }
}

const rootReducer = combineReducers({ aboutMe: aboutMeReducer });

export default rootReducer;
