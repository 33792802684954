import React from 'react';
import {Box, Link, makeStyles, Typography} from "@material-ui/core";
import SocialContainer from "../SocialContainer/SocialContainer";
import Title from "../Title/Title";

const useStyles = makeStyles((theme) => ({
    footer:{
        background: "#0d101f",
        color: "#ffffff"
    },
    lead:{
        opacity:0.7
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        border:'none'
    },
    contactMeText:{
        color: "#ffffff88"
    },
    emailLink: {
        color: theme.palette.secondary.light,
        position: "relative",
        fontWeight: 400,
        display: "inline-flex",
        justifyContent: "center",
        "&:after": {
            content: "''",
            width: 0,
            height: "2px",
            borderRadius: "20px",
            backgroundColor: theme.palette.secondary.light,
            position: "absolute",
            right: 0,
            bottom: 0,
            transition: "all 0.5s ease"
        },
        "&:hover": {
            textDecoration:"none",
            "&:after": {
                width: "100%",
                left: 0,
            }
        }
    },
}));

const Footer = () => {
    const classes = useStyles()
    const footerText = `Dhruv Mehta / ${new Date().getFullYear()}`
    return (<Box className={classes.footer} letterSpacing={6} id="contact">
        <Title title="Find Me"/>
        <Typography variant="h6" align="center" gutterBottom={true} className={classes.contactMeText}>
            If you have any question, send me <Link href="mailto:dhruvmehta3.142@gmail.com" className={classes.emailLink}>e-mail</Link> or just follow me on the socials.
        </Typography>
        <Box display="flex" justifyContent="center">
            <SocialContainer/>
        </Box>
        <Box p={4}>
            <Typography align="center" component="div">
                <Box letterSpacing={6} m={1} className={classes.lead}>design and code by:</Box> <Box letterSpacing={4}>{footerText}</Box>
            </Typography>
        </Box>
       </Box>);
}


export default Footer;
