import React from "react";
import { connect } from 'react-redux'
import Hero from "../hero/hero";
import Projects from "../Projects/Projects";
import { Box } from "@material-ui/core";

const Body = (props) => {

  return (
        <Box>
          <Hero about={props.me} />
          <Projects />
        </Box>
  );
};

const getMyBio = (state)=>{
    return state.about;
}; 

const mapStateToProps = state => ({
    me: getMyBio(state.aboutMe)
});

export default connect(mapStateToProps, null)(Body);
