import React from 'react';
import { Container } from '@material-ui/core';

const BoxContainer = (props) => {
    return (
        <Container m={props.m} p={props.p} className={props.className}>
            {props.children}
        </Container>
    );
};

export default BoxContainer;
