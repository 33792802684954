import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import linkedin from "../../image/icons/linkedin.svg";
import twitter from "../../image/icons/twitter.svg";
import { Link } from "@material-ui/core";
import { connect } from "react-redux";
import GithubIcon from "../Icons/GithubIcon";

const useStyles = makeStyles((theme) => ({
  socialLandingConnect: {
    display: "inline-flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "initial",
    },
  },
  githubIcon: {
    fill: theme.palette.type === "dark" ? "white" : "black",
  },
}));

const SocialContainer = (props) => {
  const { social_urls } = props;
  const classes = useStyles();
  return (
    <div className="social-landing-connect-container">
      <Box className={classes.socialLandingConnect}>
        <div className="social-icons-container">
          <Link href={social_urls.github} target="_blank" rel="noopener">
            {/* <img className="social-icon" src={github} alt="Github" /> */}
            <GithubIcon
              className={classes.githubIcon}
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              space="preserve"
              background="white"
            />
          </Link>
        </div>
        <div className="social-icons-container">
          <Link href={social_urls.linkedin} target="_blank" rel="noopener">
            <img className="social-icon" src={linkedin} alt="LinkedIn" />
          </Link>
        </div>
        <div className="social-icons-container">
          <Link href={social_urls.twitter} target="_blank" rel="noopener">
            <img className="social-icon" src={twitter} alt="Twitter" />
          </Link>
        </div>
      </Box>
    </div>
  );
};
const getSocialUrlsFromState = (aboutMe) => {
  return {
    dribbble: "#",
    github: "#",
    behance: "#",
    linkedin: "#",
    twitter: "#",
    ...aboutMe.about.social_urls,
  };
};

const mapsStateToProps = (state) => ({
  social_urls: getSocialUrlsFromState(state.aboutMe),
});

export default connect(mapsStateToProps, null)(SocialContainer);
